function TcfApiAdapter( window ) {
  // internal purposes, non IAB conform
  this.UTOPIA_PURPOSES = {
    FUNCTIONAL: '1',
    WRITE_COOKIES: '2',
    ANALYTICS: '3',
    PUSH_ENGAGE: '4',
    FULL_CONSENT: '6'
  };

  // internal functionality to IAB PURPOSES
  this.FUNCTION_TO_PURPOSE = {
    PUSH_ENGAGE: '1',
    WRITE_COOKIES: '1',
    CREATE_PERSONALIZED_ADS_PROFILE: '3'
  };

  // custom vendor ids relevant to the website
  const CUSTOM_VENDOR_IDS = {
    ANALYTICS: '5e542b3a4cd8884eb41b5a72'
  };

  // These IAB purposes are not supported by us and shouldn't be checked
  const SKIP_PURPOSES = [5, 6];
  const self = this;

  // constructor
  function init() {
    window.MY_CONSENT_PREFERENCES = window.MY_CONSENT_PREFERENCES || {};
    window.__tcfapi( 'addEventListener', 2, function( tcData, success ) {
      if ( success === true ) {
        if ( tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete' ) {
          // consent given previously OR consent given right now
          window.__tcfapi( 'getCustomVendorConsents', 2, function( vendorConsents, success ) {

            if ( success ) {
              // add custom vendors to validation object
              tcData.customVendors = {};
              tcData.customVendors.consents = vendorConsents.consentedVendors.filter( function( vendor ) {
                return vendorConsents.grants[vendor._id].vendorGrant === true;
              } );

              self.validate( tcData );
            }
          } );
        }
      } else {
        throw Error( 'addEventListener throws error' );
      }
    } );
  }

  init();

  // private
  function allowCustomVendor( data, vendorId ) {
    if ( data.customVendors.consents.length === 0 ) {
      return false;
    }

    return !!data.customVendors.consents.filter( function( item ) {
      return item._id === vendorId;
    } ).length;
  }

  // check if all standard IAB TCF v2.0 purposes were granted
  function allPurposesGiven( data ) {
    if ( !data.purpose.consents ) {
      return false;
    }

    let keys = Object.keys( data.purpose.consents );
    // compare length to legitimateInterests, because they always contain the full set of supported purposes
    if ( keys.length < Object.keys( data.purpose.legitimateInterests ).length ) {
      return false;
    }

    for ( let i = 1; i <= keys.length; i++ ) {
      if ( !data.purpose.consents[i] && SKIP_PURPOSES.indexOf( i ) === -1 ) {
        return false;
      }
    }

    return true;
  }

  // public

  // check for granted IAB TCF v2 purposes by purpose id ( 1-10 )
  this.allowByPurpose = function( data, purpose ) {
    if ( !data.purpose.consents ) {
      return false;
    }

    return data.purpose.consents[purpose];
  };

  this.validate = function( tcData ) {
    let websitePurposes = {};
    websitePurposes[this.UTOPIA_PURPOSES.FUNCTIONAL] = true;
    websitePurposes[this.UTOPIA_PURPOSES.PUSH_ENGAGE] = this.allowByPurpose( tcData, this.FUNCTION_TO_PURPOSE.PUSH_ENGAGE );

    let tagManagerPurposes = {};
    tagManagerPurposes[this.UTOPIA_PURPOSES.ANALYTICS] = allowCustomVendor( tcData, CUSTOM_VENDOR_IDS.ANALYTICS );
    tagManagerPurposes[this.UTOPIA_PURPOSES.WRITE_COOKIES] = this.allowByPurpose( tcData, this.FUNCTION_TO_PURPOSE.WRITE_COOKIES );
    tagManagerPurposes[this.UTOPIA_PURPOSES.FULL_CONSENT] = allPurposesGiven( tcData );

    /*eslint-disable */
    window.MY_CONSENT_CALLBACK( websitePurposes, tagManagerPurposes, tcData );
    /*eslint-enable */
  };
}
window.tcfApiConsentAdapter = new TcfApiAdapter( window );
